import axios from "axios"
import ApiRoot from "@/framework/api/fetcher"
import apis from "@/framework/config/api-config"

export const getTokenByTicktAndCliant = async (tikite: any, cliantId: any) => {
  return await axios.post(`${process.env.REACT_APP_AUTHPATH}/exchangeToken`, {
    ticket: tikite,
    clientId: cliantId ? cliantId : '09f60950-6c2f-4283-a67f-2719ef5cf32e'
  })
}
export const optSecretGenerate = async (id?: string) => {
  try {
    let res = await ApiRoot({ url: apis?.auth }).auth().optSecretGenerate(id)
    console.log('optSecretGenerate data view', res)
    return res || ''
  } catch (e) {
    console.log(e)
    return ''
  }
}
