// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .second-menu {
  height: auto !important;
}
body .second-menu.ant-menu-submenu .ant-menu {
  margin: 0;
}
body .second-menu .ant-menu-submenu-title {
  margin: 0 !important;
  background-color: #fff;
}
body .second-menu.ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 46px !important;
}
body .second-menu.ant-menu-submenu > .ant-menu-submenu-title:hover {
  padding-left: 46px !important;
}
body .second-menu .ant-menu-submenu .ant-menu-submenu-title {
  padding-left: 0px !important;
}
body .second-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  padding-left: 0px !important;
}
body .second-menu .second-menu {
  padding-left: 0px !important;
}
body .second-menu li {
  list-style: none;
}
body .second-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: auto !important;
}
body .second-menu .ant-menu-item-only-child {
  height: auto !important;
  padding-left: 60px !important;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/Menus/index.less"],"names":[],"mappings":"AAAA;EAEQ,uBAAA;AAAR;AAQQ;EACI,SAAA;AANZ;AALA;EAeY,oBAAA;EACA,sBAAA;AAPZ;AAUQ;EACI,6BAAA;AARZ;AAUY;EACI,6BAAA;AARhB;AAfA;EAiCY,4BAAA;AAfZ;AAiBY;EACI,4BAAA;AAfhB;AArBA;EAyCY,4BAAA;AAjBZ;AAxBA;EA6CY,gBAAA;AAlBZ;AA3BA;EAkDY,uBAAA;AApBZ;AA9BA;EAwDY,uBAAA;EACA,6BAAA;AAvBZ","sourcesContent":["body {\n    .second-menu {\n        height: auto !important;\n\n        &.ant-menu-submenu-vertical {\n            &>.ant-menu-submenu-title {\n                // height: 48px;\n            }\n        }\n\n        &.ant-menu-submenu .ant-menu {\n            margin: 0;\n        }\n\n        .ant-menu-submenu-title {\n            margin: 0 !important;\n            background-color: #fff;\n        }\n\n        &.ant-menu-submenu>.ant-menu-submenu-title {\n            padding-left: 46px !important;\n\n            &:hover {\n                padding-left: 46px !important;\n\n            }\n        }\n\n        &.ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content {\n            // font-weight: 500;\n        }\n\n        .ant-menu-submenu .ant-menu-submenu-title {\n            padding-left: 0px !important;\n\n            &:hover {\n                padding-left: 0px !important;\n            }\n        }\n\n        .second-menu {\n            padding-left: 0px !important;\n        }\n\n        li {\n            list-style: none\n        }\n\n        .ant-menu-sub.ant-menu-inline>.ant-menu-item {\n            // .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {\n            height: auto !important;\n            // line-height: 48px !important;\n\n        }\n\n        .ant-menu-item-only-child {\n            height: auto !important;\n            padding-left: 60px !important;\n            // line-height: 48px !important;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
