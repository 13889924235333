/* eslint-disable import/no-anonymous-default-export */
import { getJSONfromService } from '@/utils/utils'
import antd_en_us from 'antd/lib/locale/en_US'
import antd_zh_cn from 'antd/lib/locale/zh_CN'

export type TLangKey = 'en_US' | 'zh_CN' | null | undefined;

// 远程获取已经翻译好的数据. 目前没有做好语言切换, 先暂时获取所有翻译语言,并且默认en-US。
// tudo： 根据local获取远程语言数据，减少请求
// 后续有新增key 可以到https://app.phrase.com/ 新增
export const languageMaps: any = {
  'en-US': 'en_US',
  'zh-CH': 'zh_CN',
}
export const andtdLoacal: any = {
  antd: {
    'en_US': antd_en_us,
    'zh_CN': antd_zh_cn,
  },
}
export default async function (localLanguage: string): Promise<any> {
  const mapData: any = {
    en_US: 'en-US',
    zh_CN: 'zh-CN'
  }
  const data = await getJSONfromService(mapData[localLanguage])
  const localData: any = {}
  localData[localLanguage] = data
  return {
    loca: localData
  }
}
