import moment from "moment-timezone";
import { getDataFormSeamCookie } from "./utils";

const _momentWithStoreSetting = (config: any, moment: moment.Moment, isformat?: boolean, format?: string): string | moment.Moment => {
    const objectMap = JSON.parse(config ?? '{}')
    const numberTime = objectMap?.timeZone?.split(')')?.[0].replace('(', '').replace('UTC+', '').replace('UTC-', '').replace(':', '.')
    let translateTime = 0
    if (objectMap?.timeZone?.indexOf('UTC+') > -1) {
        translateTime = Number(numberTime) + 0
    } else {
        translateTime = Number(numberTime) * -1
    }
    moment = moment.tz(objectMap?.area)?.utc()
    if (moment.tz(objectMap?.area).isDST()) {
        const c = Number(moment.tz(objectMap?.area).format().split('T')[1].split(/\+|\-/)[1].replace(':', '.'))
        const summerLongtime = moment.tz(objectMap?.area).format().indexOf('+') > -1 ? c : c * -1
        if (objectMap?.isEnableDaylight) {
            translateTime = summerLongtime
        }
    }
    if (isformat) {
        return moment.tz(objectMap?.area)?.utc().add(translateTime, 'hours').format(format ? format : objectMap.dateFormat.toLocaleUpperCase() + ' ' + objectMap.timeFormat)
    } else {
        return moment.tz(objectMap?.area)?.utc().add(translateTime, 'hours')
    }
}

export default function customsMoments(str?: any, format?: any, language?: any, strict?: any) {
    const storeSetting = getDataFormSeamCookie('rc_sc_store_config') ?? "{}"
    //显示的时候转换零时区为当前时区，例如东八区， 或者按照当前店铺时区做转换，包含夏令时以及当前的现实格式。
    moment.prototype.display = function (format?: string) {
        return _momentWithStoreSetting(storeSetting, this, true, format)
    }
    moment.prototype.d = function () {
        return _momentWithStoreSetting(storeSetting, this, false)
    }
    //发送到后台的数据全部转换为零时区
    moment.prototype.send = function () {
        return this?.utc().format()
    }
    // @ts-ignore
    const moments: Moments = moment(str, format, language, strict)
    return moments
}
export interface Moment extends moment.Moment {
    display: (str?: string) => string
    send: () => string
    d: () => Moment
}
