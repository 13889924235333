// 引入icon样式文件
// import "../assets/css/iconfont/iconfont"

import home from '../../src/assets/icon/icons8-home (2).json'
import React from 'react'
export interface MenuObject {
  name: string
  value: string
  url: string
  key: string // for auth permission key 
  icon?: any
  activekey: string // for menu item active
  langKey: string
  children?: MenuObject[]
}
export const menus: MenuObject[] = [
  {
    name: 'Quick Start',
    value: 'store_management',
    url: '/setting-quick-start',
    key: "quickStart",
    icon: <span className='icon iconfont icon-quickstart' />,
    langKey: 'menu.quickStart',
    activekey: 'setting-quick-start'
  },
  {
    name: 'Store',
    value: 'store_management',
    url: 'details',
    key: 'store',
    icon: <span className="icon iconfont icon-store" />,
    langKey: 'menu.store_management',
    activekey: 'sc-store-manage',
    children: [
      {
        name: 'Store Details',
        value: 'store_details',
        url: '/sc-store-manage',
        key: 'store.storeDetails',
        langKey: 'menu.store_details',
        activekey: 'details',
        // }, {
        //   name: 'Basic Information',
        //   value: 'store_basic_information',
        //   url: '/sc-store-manage/basic-information',
        //   key: 'store.basicInformation',
        //   langKey: 'menu.basic_information',
        // }, {
        //   name: 'Region / Language',
        //   value: 'store.regionLanguage',
        //   url: '/sc-store-manage/region-language',
        //   key: 'store.regionLanguage',
        //   langKey: 'menu.region_language',
        // }, {
        //   name: 'Currency',
        //   value: 'store_currency',
        //   url: '/sc-store-manage/currency',
        //   key: 'store.currency',
        //   langKey: 'menu.store_currency',
        // }, {
        //   name: 'Standards and format',
        //   value: 'store_standards_format',
        //   url: '/sc-store-manage/standards-format',
        //   key: 'store.standardsAndFormat',
        //   langKey: 'menu.store_standards_format',
      },
    ],
  },
  {
    name: 'Measurements',
    value: 'measurements_manage',
    url: '/sc-measurements-manage/details',
    key: 'measurements',
    activekey: 'sc-measurements-manage',
    // icon: <img src={measureIcon} alt="" />,
    icon: <span className='icon iconfont icon-Measurement'></span>,
    langKey: 'menu.measurements_manage',
    children: [
      {
        name: 'Measurement Family',
        value: 'measurement_family',
        url: '/sc-setting-measurement',
        activekey: 'family',
        key: 'measurements.measurementFamily',
        langKey: 'menu.measurement_family',
      },
    ],
  },
  {
    name: 'Shipping method',
    value: 'setting_shipping_method',
    url: '/sc-setting-shipping-method/transition-methods',
    key: 'shippingMethod',
    icon: <span className="icon iconfont icon-a-shippingmethod" />,
    activekey: 'sc-setting-shipping-method',
    langKey: 'menu.shippingMethod',
    children: [
      {
        name: 'Transition Methods',
        value: 'transition-methods',
        url: '/sc-setting-shipping-method/transition-methods',
        activekey: 'transition-methods',
        key: 'shippingMethod.transitionMethods',
        langKey: 'menu.shippingMethod.transitionMethods',
      }, {
        name: 'Shipping Methods',
        value: 'shipping-methods',
        activekey: 'shipping-methods',
        url: '/sc-setting-shipping-method/shipping-methods',
        key: 'shippingMethod.shippingMethods',
        langKey: 'menu.shippingMethod.shippingMethods',
      }, {
        name: 'Carrier Setting',
        value: 'carrier-setting',
        activekey: 'carrier-setting',
        url: '/sc-setting-shipping-method/carrier-setting',
        key: 'shippingMethod.carrierSetting',
        langKey: 'menu.shippingMethod.carrierSetting',
      }
    ],
  },
  {
    name: 'Org Management',
    value: 'org_management',
    url: '/sc-sys-manage/org',
    key: 'orgManagement',
    activekey: 'sc-sys-manage',
    icon: <span className="icon iconfont icon-org" />,
    langKey: 'menu.org_management',
    children: [
      {
        name: 'Org Setting',
        value: 'org',
        url: '/sc-sys-manage/org',
        activekey: 'org',
        // url: "/",
        key: 'orgManagement.orgSetting',
        langKey: 'menu.org',
      },
    ],
  },
  {
    name: 'Tax Management',
    value: 'tax_management',
    url: '/sc-tax-manage/tax',
    activekey: 'sc-tax-manage',
    key: 'taxManagement',
    icon: <span className="icon iconfont icon-a-shuiwu1" />,
    langKey: 'menu.tax_management',
    children: [
      {
        name: 'Tax Setting',
        activekey: 'tax',
        value: 'tax',
        url: '/sc-tax-manage/tax',
        key: 'taxManagement.taxsetting',
        langKey: 'menu.tax',
      },
    ],
  },
  {
    name: 'Adress management',
    value: 'adress_management',
    url: '/sc-address-manage/schema',
    activekey: 'sc-address-manage',
    key: 'address',
    icon: <span className="icon iconfont icon-dizhi" />,
    langKey: 'menu.adress_management',
    children: [
      {
        name: 'suggestions',
        activekey: 'scAddressManageSchema',
        value: 'schema',
        url: '/sc-address-manage/schema',
        key: 'address.addressSchema',
        langKey: 'menu.address schema',
      },
      {
        name: 'suggestions',
        activekey: 'scAdressManageSuggestions',
        value: 'suggestions',
        url: '/sc-address-manage/suggestion',
        key: 'address.addressSuggestions',
        langKey: 'menu.suggestions',
      },
      {
        name: 'scope',
        activekey: 'scAddressScope',
        value: 'scope',
        url: '/sc-address-manage/scope',
        key: 'address.addressScope',
        langKey: 'menu.address scope',
      },
    ],
  },
  {
    name: 'User',
    value: 'user',
    url: '/sc-user-setting',
    key: 'users',
    activekey: 'sc-user-setting',
    icon: <span className="icon iconfont icon-users" />,
    langKey: 'menu.user',
    children: [
      {
        name: 'User List',
        value: 'userList',
        url: '/sc-user-setting/user-list',
        activekey: 'user-list',
        key: 'users.userPageList',
        langKey: 'menu.User List',
      }, {
        name: 'Activity Logs',
        value: 'activityLogs',
        url: '/sc-user-setting/activity-logs',
        activekey: 'activity-logs',
        key: 'users.activityLogs',
        langKey: 'menu.Activity Logs',
      }
    ],
  },
  {
    name: 'Application',
    value: 'app_setting',
    url: '/sc-app-setting',
    activekey: 'sc-app-setting',
    key: 'application',
    icon: <span className="icon iconfont icon-app" />,
    langKey: 'menu.app_setting',
    // children: [
    //   {
    //     name: 'Tax Setting',
    //     value: 'tax',
    //     url: '/sc-tax-manage/tax',
    //     key: 'tax',
    //     langKey: 'menu.tax',
    //   },
    // ],
  },
  {
    name: 'Translation',
    value: 'translation',
    url: '/sc-setting-translation',
    key: 'translation',
    activekey: 'sc-setting-translation',
    icon: <span className="icon iconfont icon-language" />,
    langKey: 'menu.translation',
  }
]
