import { Suspense, useEffect, useContext, useState } from 'react'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import Router from './routers/index'
import './App.less'
import 'animate.css'
import '@/assets/css/global.less'
import '@/assets/css/iconfont/iconfont.css'
import Loading from '@/assets/images/loading2.gif'
import { ConfigProvider } from 'antd'
import locals, { andtdLoacal, TLangKey } from './locale'
import classNames from 'classnames'
import useRefreshTime from './contexts/intl'
import intl from 'react-intl-universal'
import zh_CN from '@/locale/translation/zh-CH'
import en_US from '@/locale/translation/en-GB'
import { useAtom } from 'jotai'
import { langAtom } from './store/lang.store'
import Cookies from 'js-cookie'
import { getDataFormSeamCookie, removeToken } from '@/utils/utils'
import { getcliantId, setUserInfofn } from '@/common/components/authloading'
import queryString from 'query-string'
import { modules } from '@/common/components/Header'
import { getMenuData } from '@/components/common/MenusWrapper'
ConfigProvider.config({
  theme: { primaryColor: getDataFormSeamCookie('theme') || '#ee4d2d', },
});


function App() {
  const [loading, setLoading] = useState<boolean>(true)
  const [refreshTime, setRefreshTime] = useRefreshTime()
  const [lang, setLang] = useAtom(langAtom)
  let router = Router
  let appClassName = 'sales-app-container'
  let Routers = useRoutes(router as any)
  const navigate = useNavigate()
  const location = useLocation()
  const checkHasPermistion = async () => {
    const query:any = queryString.parse(window.location.search)
    const token = query.token ?? ''
    const init_page = query.init_page ?? ''
    if (query.rc_access_ticket) {
      Cookies.set('rc_access_ticket', query.rc_access_ticket, { expires: 1000 * 60 * 60 * 24, path: '/' })
    }

    if (!Cookies.get('rc_access_token') && !['/login', '/register', '/authloading'].includes(location.pathname)) {
      if (!Cookies.get('rc_access_ticket')) {
        removeToken()
        if (process.env.NODE_ENV == 'development') {
          setLoading(false)
          navigate('/login')
        } else {
          window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
        }
      }
    } else {
      if (!token && !init_page && process.env.NODE_ENV !== 'development') { //排除九宫格切换，初始化登录。每次刷新都要校验是否已经退出登录
        await setUserInfofn(Cookies.get('rc_access_token'), getcliantId())
      }
      setLoading(false)
    }
  }
  const routerHistory:any = useLocation()
  const changeSeo = (routerHistory: Location) => {
    if (routerHistory.pathname == '/authloading' || routerHistory.pathname == '/store/select') return true
    const activeTab = modules.find((items: { url: string | string[] }) => items.url.indexOf(window.location.host) > -1)?.value ?? 0
    const menuData = getMenuData(activeTab)
    const title = document.querySelector('title')
    const findActiveName = menuData.find((i: { activekey: string }) => i.activekey == routerHistory.pathname.split('/')[1])
    const menuName = routerHistory.pathname !== '/' ?
      intl.get((findActiveName?.langKey) ?? 'loading') == '' ? findActiveName?.name : intl.get(findActiveName?.langKey) :
      intl.get(menuData[0].langKey ?? 'loading')
    try {
      // @ts-ignore
      title.innerHTML = `${menuName.charAt(0).toUpperCase() + menuName.slice(1)} | ${getDataFormSeamCookie('rc_sc_login_module')} | DTC Platform`
    } catch (error) {
      //@ts-ignore
      title.innerHTML = `DTC Platform`
    }
  }
  useEffect(() => {
    checkHasPermistion()
    changeSeo(routerHistory)
  }, [routerHistory])
  useEffect(() => {
    // @ts-ignore
    setLang(getDataFormSeamCookie('locale') ?? "en_US")
  }, [])
  useEffect(() => { initIntl(setRefreshTime) }, [lang])
  const initIntl = async (refreshPage: any) => {
    let languageData = {
      loca: {
        zh_CN: zh_CN,
        en_US: en_US
      }
    }
    intl.init({
      // @ts-ignore
      currentLocale: lang,
      locales: languageData?.loca,
      // fallbackLocale: 'en_US'
    }).then(() => {
      refreshPage(Math.random() * 10000)
    }).catch(error => {
      console.log(error)
    });
  }

  if (loading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
      </div>
    )
  }

  return (
    <div className='App text-center languageVersion4' key={refreshTime}>
      <ConfigProvider locale={andtdLoacal?.antd?.[getDataFormSeamCookie('locale') ?? 'en_US']}>
        <Suspense
          fallback={
            <div className='w-full h-screen flex justify-center items-center'>
              <img className='mb-15' alt='' style={{ width: '100px' }} src={Loading} />
            </div>
          }
        >
          <div className={classNames('text-left', appClassName)}>{Routers}</div>
        </Suspense>
      </ConfigProvider>
    </div>
  )
}

export default App
