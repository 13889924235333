/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-01-17 11:05:26
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-14 14:47:42
 * @FilePath: \rc_china_storeportal\src\routers\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from 'react'
import { lazy } from 'react' // 路由懒加载
const Layout = lazy(() => import('@/common/components/Layout'))
const Home = lazy(() => import('../views/index'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const StoreDashboard = lazy(() => import('@/views/storeDashboard'))
const AuthLoading = lazy(() => import('@/views/authLoading'))

const StoreDetails = lazy(() => import('../views/storeDetails'))
const StoreBasicInformation = lazy(() => import('../views/storeBasicInformation'))
const StoreCurrency = lazy(() => import('../views/storeCurrency'))
const StoreStandardsFormat = lazy(() => import('../views/storeStandardsFormat'))
const StoreRegionLanguage = lazy(() => import('../views/storeRegionLanguage'))
const AddressSchema = lazy(() => import('@/views/addressSchema'))
const AddressDisplay = lazy(() => import('@/views/addressDisplay'))
const AdressSuggestions = lazy(() => import('@/views/adressManagement'))
const AddressScope = lazy(() => import('../views/addressScope'))
const MeasurementFamilyEdit = lazy(() => import('../views/measurementFamilyEdit'))
const MeasurementFamilyList = lazy(() => import('../views/measurementFamilyList'))
const MeasurementUnitEdit = lazy(() => import('../views/measurementUnitEdit'))
const MeasurementUnitList = lazy(() => import('../views/measurementUnitList'))
const Org = lazy(() => import('../views/settingCenter/org'))
const OrgItem = lazy(() => import('../views/settingCenter/org/detail'))
const OrgDetail = lazy(() => import('../views/settingCenter/orgDetail'))
const CreateWebsite = lazy(() => import('@/views/createWebsite'))
const TransitionMethodList = lazy(() => import('../views/settingCenter/shippingMethods/transitionMethod/list'))
const AddOrEditTransitionMethod = lazy(
  () => import('../views/settingCenter/shippingMethods/transitionMethod/addAndEdit'),
)
const ShippingMethodList = lazy(() => import('../views/settingCenter/shippingMethods/shippingMethod/list'))
const AddOrEditShippingMethod = lazy(() => import('../views/settingCenter/shippingMethods/shippingMethod/addAndEdit'))
const StoreCarrierSetting = lazy(() => import('@/views/storeCarrierSetting'))
const Users = lazy(() => import('../views/users'))
const AddUser = lazy(() => import('../views/addUser'))
const StoreTax = lazy(() => import('../views/storeTax'))
const UserSetting = lazy(() => import('../views/userSetting'))
const ActivityLogs = lazy(() => import('../views/activityLogs'))
const Applications = lazy(() => import('../views/applications'))
const AddApp = lazy(() => import('../views/addApp'))
const AppSetting = lazy(() => import('../views/appSetting'))
const Translation = lazy(() => import('@/views/translation'))
const TranslationPage = lazy(() => import('@/views/translation/translationPage'))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <StoreDashboard /> },
      {
        path: 'setting-quick-start',
        breadcrumbName: 'dashboard',
        element: <StoreDashboard />,
        key: 'store_dashboard'
      },
      {
        path: 'sc-store-manage',
        breadcrumbName: 'Store',
        children: [
          { index: true, element: <StoreDetails />, key: 'store' },
          // {
          //   path: 'details',
          //   breadcrumbName: 'Store Details',
          //   element: <StoreDetails />,
          // },
          {
            path: 'basic-information',
            breadcrumbName: 'Store Basic Information',
            element: <StoreBasicInformation />,
          },
          {
            path: 'currency',
            breadcrumbName: 'Currency',
            element: <StoreCurrency />,
          },
          {
            path: 'standards-format',
            breadcrumbName: 'Standards and format',
            element: <StoreStandardsFormat />,
          },
          {
            path: 'region-language',
            breadcrumbName: 'Store Region Language',
            element: <StoreRegionLanguage />,
          },
        ],
      },
      {
        path: 'sc-address-manage',
        breadcrumbName: 'menu.adress_management',
        children: [
          { index: true, element: <AddressSchema /> },
          { path: 'schema', breadcrumbName: 'menu.address schema', element: <AddressSchema /> },
          { path: 'display', breadcrumbName: 'menu.address display', element: <AddressDisplay /> },
          { path: 'suggestion', breadcrumbName: 'menu.suggestions', element: <AdressSuggestions /> },
          {
            path: 'scope',
            breadcrumbName: 'menu.address scope',
            element: <AddressScope />
          }
        ]
      },
      {
        path: 'sc-setting-measurement',
        breadcrumbName: 'Measurements',
        children: [
          { index: true, element: <MeasurementFamilyList /> },
          // {
          //   path: 'family',
          //   breadcrumbName: 'Measurement Family',
          //   element: <MeasurementFamilyList />,
          // },
          {
            path: 'add-family',
            breadcrumbName: 'Add Measurement Family',
            element: <MeasurementFamilyEdit />,
          },
          {
            path: 'edit-family',
            breadcrumbName: 'Edit Measurement Family',
            element: <MeasurementFamilyEdit />,
          },
          {
            path: 'unit',
            breadcrumbName: 'Measurement Unit',
            element: <MeasurementUnitList />,
          },
          {
            path: 'add-unit',
            breadcrumbName: 'Add Measurement Unit',
            element: <MeasurementUnitEdit />,
          },
          {
            path: 'edit-unit',
            breadcrumbName: 'Edit Measurement Unit',
            element: <MeasurementUnitEdit />,
          },
        ],
      },
      {
        path: 'sc-sys-manage',
        breadcrumbName: 'Org Management',
        children: [
          { index: true, element: <Org /> },
          {
            path: 'org',
            breadcrumbName: 'Org Setting',
            children: [
              { index: true, element: <Org /> },
              {
                path: 'add-org',
                breadcrumbName: 'Add Org',
                element: <OrgDetail />,
              },
              {
                path: 'detail-org',
                breadcrumbName: 'Org Detail',
                element: <OrgItem />,
              },
              {
                path: 'edit-org',
                breadcrumbName: 'Edit Org',
                element: <OrgDetail />,
              },
              {
                path: 'create-website',
                breadcrumbName: 'Online Store Creation',
                children: [
                  { index: true, element: <CreateWebsite /> },
                  // {
                  //   path: 'tax',
                  //   breadcrumbName: 'Tax Setting',
                  //   children: [{ index: true, element: <CreateWebsite /> }],
                  // },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'sc-setting-shipping-method',
        breadcrumbName: 'Shipping Method',
        children: [
          { index: true, element: <TransitionMethodList /> },
          {
            path: 'transition-methods',
            breadcrumbName: 'Transition Methods',
            children: [
              { index: true, element: <TransitionMethodList /> },
              {
                path: 'add',
                breadcrumbName: 'Add Transition Method',
                element: <AddOrEditTransitionMethod />,
              },
              {
                path: 'edit',
                breadcrumbName: 'Edit Transition Method',
                element: <AddOrEditTransitionMethod />,
              },
            ],
          },
          {
            path: 'shipping-methods',
            breadcrumbName: 'Shipping Methods',
            children: [
              { index: true, element: <ShippingMethodList /> },
              {
                path: 'add-shipping',
                breadcrumbName: 'Add Shipping Method',
                element: <AddOrEditShippingMethod />,
              },
              {
                path: 'edit-shipping',
                breadcrumbName: 'Edit Shipping Method',
                element: <AddOrEditShippingMethod />,
              },
            ],
          },
          {
            path: 'carrier-setting',
            breadcrumbName: 'Carrier Setting',
            children: [{ index: true, element: <StoreCarrierSetting /> }],
          }
        ],
      },
      {
        path: 'sc-tax-manage',
        breadcrumbName: 'Tax Management',
        children: [
          { index: true, element: <StoreTax /> },
          {
            path: 'tax',
            breadcrumbName: 'Tax Setting',
            children: [{ index: true, element: <StoreTax /> }],
          },
        ],
      },
      {
        path: 'sc-user-setting',
        breadcrumbName: 'Users',
        children: [
          { index: true, element: <Users /> },
          {
            path: 'user-list',
            breadcrumbName: 'User List',
            children: [{ index: true, element: <Users /> }, {
              path: 'user-setting',
              breadcrumbName: 'User Setting',
              children: [{ index: true, element: <UserSetting /> }],
            }, {
              path: 'user-add',
              breadcrumbName: 'Add User',
              children: [{ index: true, element: <AddUser /> },],
            }],
          }, {
            path: 'activity-logs',
            breadcrumbName: 'Activity Logs',
            children: [{ index: true, element: <ActivityLogs /> }],
          },
        ],
      },
      {
        path: 'sc-app-setting',
        breadcrumbName: 'Applications.Applications',
        children: [
          { index: true, element: <Applications /> },
          // {
          //   path: 'app-list',
          //   breadcrumbName: 'Application List',
          //   children: [{ index: true, element: <Applications /> }],
          // },
          {
            path: 'app-add',
            breadcrumbName: 'Add application',
            children: [{ index: true, element: <AddApp /> }],
          },
          {
            path: 'app-setting',
            breadcrumbName: 'Application Setting',
            children: [{ index: true, element: <AppSetting /> }],
          },


        ],
      },
      {
        path: 'sc-setting-translation',
        breadcrumbName: 'menu.translation',
        children: [
          { index: true, element: <Translation /> },

          {
            path: 'dtc-platform',
            breadcrumbName: 'translation.DTC Platform',
            element: <TranslationPage />
          },
          {
            path: 'store-front',
            breadcrumbName: 'translation.Store Front',
            element: <TranslationPage />
          }
        ]
      }
    ],
  },



  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '*', element: <Home />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
